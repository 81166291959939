import React from "react";
import ReactDOM from "react-dom/client";

export const baseUrl = new URL(window.document.URL);
function importBuildTarget() {
  if (baseUrl.pathname.startsWith("/p/")) {
    return import("./user");
  }
  if (process.env.REACT_APP_BUILD_TARGET === "front") {
    return import("./front");
  } else if (process.env.REACT_APP_BUILD_TARGET === "user") {
    return import("./user");
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(<Environment />);
});
